import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  AppointmentCalendar,
  AppointmentsList,
  Calendar,
  NewAppointment,
} from "../components";
import {
  GetAllEmployees,
  GetUsers,
  calendarSearch,
  clearEvents,
  createAppointment,
  createEvent,
  deleteAppointment,
  getAppointmentSearch,
  getAllCampaigns
} from "../store/actions";
import { getLeads } from "../store/actions/lead";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateAppointment: (payload, cb) =>
      dispatch(createAppointment(payload, cb)),
    onSearchAppointments: (payload, cb) =>
      dispatch(getAppointmentSearch(payload, cb)),
    onDeleteAppointment: (payload, cb) =>
      dispatch(deleteAppointment(payload, cb)),
    getAllEmployeesList: () => dispatch(GetAllEmployees()),
    getAllUser: () => dispatch(GetUsers()),
    onSearchCalendar: (id, cb) => dispatch(calendarSearch(id, cb)),
    onCreateEvent: (payload, cb) => dispatch(createEvent(payload, cb)),
    getAllCampaigns: () => dispatch(getAllCampaigns()),
    getLeads: () => dispatch(getLeads()),
  };
};

export const AppointmentContainer = () => {
  let location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/AppointmentsList":
      ComponentToRender = AppointmentsList;
      break;
    case "/NewAppointment":
      ComponentToRender = NewAppointment;
      break;
    case "/Calendar":
      ComponentToRender = AppointmentCalendar;
      break;
    default:
      ComponentToRender = null;
  }

  let Container = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentToRender);
  return <Container />;
};
