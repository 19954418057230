import {
  CALENDAR_SEARCH_FAILURE,
  CALENDAR_SEARCH_IN_PROGRESS,
  CALENDAR_SEARCH_SUCCESS,
  CLEAR_EVENT,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_IN_PROGRESS,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_IN_PROGRESS,
  CREATE_EVENT_SUCCESS,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_IN_PROGRESS,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_SUCCESS,
  GET_APPOINTMENT_SEARCH_FAILURE,
  GET_APPOINTMENT_SEARCH_IN_PROGRESS,
  GET_APPOINTMENT_SEARCH_SUCCESS,
  GET_All_EMPLOYEES_FAILURE,
  GET_All_EMPLOYEES_IN_PROGRESS,
  GET_All_EMPLOYEES_SUCCESS,
  GET_All_NEW_EMPLOYEES_IN_PROGRESS,
  GET_All_NEW_EMPLOYEES_SUCCESS,
  GET_All_NEW_EMPLOYEES_FAILURE
} from "../actions/appointment/types";
import initialState from "../initialState";

export const appointment = (state = initialState.appointment, action) => {
  switch (action.type) {
    case CREATE_APPOINTMENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_APPOINTMENT_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        appointmentData: {},
      };
    case GET_APPOINTMENT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        appointmentData: action?.data,
      };
    case GET_APPOINTMENT_SEARCH_FAILURE:
      return {
        ...state,
        appointmentData: {},
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case DELETE_APPOINTMENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case DELETE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_All_EMPLOYEES_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        allEmployeelist: [],
      };
    case GET_All_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        allEmployeelist: action?.data,
      };
    case GET_All_EMPLOYEES_FAILURE:
      return {
        ...state,
        allEmployeelist: [],
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
      case GET_All_NEW_EMPLOYEES_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        allNewEmployeelist: [],
      };
    case GET_All_NEW_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        allNewEmployeelist: action?.data,
      };
    case GET_All_NEW_EMPLOYEES_FAILURE:
      return {
        ...state,
        allNewEmployeelist: [],
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CALENDAR_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isCalendarLoading: true,
        calendarInfo: [],
        error: false,
        errorMessage: "",
      };
    case CALENDAR_SEARCH_SUCCESS:
      return {
        ...state,
        isCalendarLoading: false,
        error: false,
        errorMessage: "",
        calendarInfo: action?.data,
      };
    case CALENDAR_SEARCH_FAILURE:
      return {
        ...state,
        calendarInfo: [],
        isCalendarLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CREATE_EVENT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CLEAR_EVENT:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        calendarInfo: [],
      }
      case DELETE_CALENDAR_EVENT_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: false,
          errorMessage: "",
        };
      case DELETE_CALENDAR_EVENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: true,
          errorMessage: action?.error?.message,
        };
      case DELETE_CALENDAR_EVENT_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: false,
          errorMessage: "",

        }
    default:
      return state;
  }
};
