import { DEVICE_STATE } from "../helpers";

const defaultState = {
  loading: false,
  data: [],
  error: false,
  errorMessage: "",
};

export default {
  loginAuth: {
    user_details: {},
    isRecord:false,
    isLoggedIn: false,
    loading: false,
    error: false,
    errorMessage: "",
    verifyOTP: {
      isLoading: false,
      error: false,
      errorMessage: "",
    },
    forgetPassord: {
      isLoading: false,
      error: false,
      errorMessage: "",
    },
    qrCode: {
      isLoading: false,
      error: false,
      errorMessage: "",
      qrUrl:"",
      userId:""
    },
    verifyQrOTP: {
      isLoading: false,
      error: false,
      errorMessage: "",
    },
    authType:{
      isLoading: false,
      error: false,
      errorMessage: "",
      twoFAAuth:false,
      twoWayAuth:false,
      resetAuth:""
    }
  },
  home: {
    isUserProdLoading: false,
    isLeadOwedLoading: false,
    error: false,
    errorMessage: "",
    userProduction: {},
    leadOwed: {},
    isleadOwedKeyLoading:false,
  },
  employee: {
    loading: false,
    error: false,
    errorMessage: "",
    empoyee_listing: {},
  },
  admin: {
    loading: false,
    error: false,
    errorMessage: "",
    admin_listing: {},
  },
  client: {
    loading: false,
    error: false,
    errorMessage: "",
    isResetLoading:false,
    client_listing: {},
  },
  prospect: {
    loading: false,
    error: false,
    errorMessage: "",
    isUpdateKeyIsloading: false,
    prospectInfo: {},
    user_details: {
      data: [],
      loading: false,
      error: false,
      errorMessage: "",
    },
    callResult:{
      isCreateLoading:false,
      options:[],
      loading: false,
      error: false,
      errorMessage: "",
    },
    isMassUpdateLoading:false,
    isExportLoading:false

  },
  lead: {
    isLoading: false,
    isAppointmentStatusUpdate:false,
    isCatKeyUpdate:false,
    leadData: [],
    error: false,
    errorMessage: "",
    leadShow: {},
    isMassUpdateLoading:false,
    notes:{
      data:[],
      loading: false,
      error: false,
      errorMessage: "",
    },
    credit:{
      data:[],
      loading: false,
      error: false,
      errorMessage: "",
    },
  },
  campaign: {
    isloading: false,
    campaignList: {},
    campaignDetails: {},
    error: false,
    errorMessage: "",
    campaignNamesWithID: {
      results: []
    },
    searchedCampaignList: {},
    calendarEvents: [],
    isCreadLeadAppLoading: false,
    isCircleIsDraging:false,
    currentCircleRadius:0,
    findCampaigns:{
      data:{},
      loading: false,
      error: false,
      errorMessage: "",
    },
    next:null,
    campaignNameSearch:[]
  },
  appointment: {
    isLoading: false,
    isCalendarLoading: false,
    appointmentData: {},
    error: false,
    errorMessage: "",
    allEmployeelist: [],
    allNewEmployeelist:[],
    calendarInfo: [],
  },
  twillio: {
    access_token: "",
    isloading: false,
    error: false,
    errorMessage: "",
    deviceState: "Offline",
    callingCardInfo: {
      isVisible: false,
      contact_no: "",
      isOutgoingOrIncoming: "",
    },
  },
  callback: {
    isLoading: false,
    callBackData: [],
    error: false,
    errorMessage: "",
    callbackDetails: {},
    isAssignedLoading:false,
  },
  profile: {
    userData: {},
    userDetails:{},
    allClients:[],
    isLoading: false,
    error: false,
    errorMessage: "",
    isCalendarLogin: false,
    calendarType: "",
    isCalenderSync: false,
    lastSyncTime: "",
  },
  reports: {
    isLoading: false,
    isStopCampaignLoading: false,
    error: false,
    errorMessage: "",
    callHistoryReports: [],
    userReports: {},
    campaignReport: {},
    currentOnlineUsers: {},
    CWTRReport: {},
  },
  invoices: {
    commercialInvoices: {},
    clientInvoices: {},
    isCreateInvoiceInProgress: false,
    isLoading: false,
    error: false,
    errorMessage: "",
    isGenerateLoading: false,
    isSendEmailLoading: false,
  },
  clientView: {
    loading: false,
    data: [],
    error: false,
    errorMessage: "",
  },
};
