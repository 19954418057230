import * as React from "react";
import Box from "@mui/material/Box";
import { CampaignInfoTable } from "./CampaignInfoTable";
import { useSelector } from "react-redux";

export const CampaignInfoParentTable = (props) => {
  const { clientData } = props;
  const [filterName, setFilterName] = React.useState("");
  const [filterActive, setFilterActive] = React.useState("all");

  let userReports = useSelector((state) => state?.reports?.userReports);

  const createData = (
    username,
    campaign,
    loginTime,
    idleTime,
    utilizationTime,
    calls,
    unavailable,
    badNumber,
    decisionMakerNotYetInterested,
    skip,
    doNotCallList,
    renewalDateAdded,
    callBackScheduled,
    nationwide,
    erie,
    busy,
    farmers,
    setAppointment,
    workDay,
    duplicate,
    mobileNumber
  ) => {
    return {
      username,
      campaign,
      loginTime,
      idleTime,
      utilizationTime,
      calls,
      unavailable,
      badNumber,
      decisionMakerNotYetInterested,
      skip,
      doNotCallList,
      renewalDateAdded,
      callBackScheduled,
      nationwide,
      erie,
      busy,
      farmers,
      setAppointment,
      workDay,
      duplicate,
      mobileNumber
    };
  };

  // Create dummy rows for the specified columns
  // const rows = [
  //   createData(
  //     "Robin Turner",
  //     "Ward, John (Plus: 10+) X Dates",
  //     "01:56:51",
  //     "00:11:02",
  //     "01:45:48",
  //     34,
  //     1,
  //     1,
  //     19,
  //     0,
  //     0,
  //     0,
  //     25,
  //     0,
  //     0,
  //     2,
  //     0,
  //     2,
  //     "2023-09-05"
  //   ),
  //   createData(
  //     "Robin Turner",
  //     "Ament, Jason X Dates (Plus 10+) X Dates",
  //     "01:56:51",
  //     "00:11:02",
  //     "01:45:48",
  //     34,
  //     1,
  //     1,
  //     19,
  //     0,
  //     0,
  //     0,
  //     25,
  //     0,
  //     0,
  //     2,
  //     0,
  //     2,
  //     "2023-09-05"
  //   ),
  //   createData(
  //     "Robin Turner",
  //     "Ward, John (Plus: 10+) X Dates",
  //     "01:56:51",
  //     "00:11:02",
  //     "01:45:48",
  //     34,
  //     1,
  //     1,
  //     19,
  //     0,
  //     0,
  //     0,
  //     25,
  //     0,
  //     0,
  //     2,
  //     0,
  //     2,
  //     "2023-09-05"
  //   ),
  //   createData(
  //     "Robin Turner",
  //     "Ament, Jason X Dates (Plus 10+) X Dates",
  //     "01:56:51",
  //     "00:11:02",
  //     "01:45:48",
  //     34,
  //     1,
  //     1,
  //     19,
  //     0,
  //     0,
  //     0,
  //     25,
  //     0,
  //     0,
  //     2,
  //     0,
  //     2,
  //     "2023-09-05"
  //   ),
  //   createData(
  //     "Robin Turner",
  //     "Ward, John (Plus: 10+) X Dates",
  //     "01:56:51",
  //     "00:11:02",
  //     "01:45:48",
  //     34,
  //     1,
  //     1,
  //     19,
  //     0,
  //     0,
  //     0,
  //     25,
  //     0,
  //     0,
  //     2,
  //     0,
  //     2,
  //     "2023-09-05"
  //   ),
  // ];

  //   {
  //     "campaign_id": 59,
  //     "campaign_name": "NEW0101",
  //     "call_count": 0,
  //     "utilization_time": "00:00:00",
  //     "preview_time": "00:00:00",
  //     "wrapup_time": "00:00:00",
  //     "talk_time": "00:00:00",
  //     "idle_time": "00:00:00",
  //     "login_time": "00:00:00",
  //     "username": "rohit birla",
  //     "work_day": "2023-11-14",
  //     "call_result_array": {
  //         "none": 0,
  //         "unavailable": 0,
  //         "bad_number": 0,
  //         "decision_maker_not_yet_interested": 0,
  //         "skip": 0,
  //         "do_not_call_list": 0,
  //         "renewal_date_added": 0,
  //         "call_back_scheduled": 0,
  //         "nationwide": 0,
  //         "erie": 0,
  //         "set_appointment": 3,
  //         "busy": 0,
  //         "farmers": 0,
  //         "duplicate": 0,
  //         "mobile_number": 0
  //     }
  // }

  const convertApiResponseToRows = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map((item) => {
      const {
        campaign_id,
        campaign_name,
        call_count,
        utilization_time,
        preview_time,
        wrapup_time,
        talk_time,
        idle_time,
        login_time,
        username,
        work_day,
        call_result_array: {
          none,
          unavailable,
          bad_number,
          decision_maker_not_yet_interested,
          skip,
          do_not_call_list,
          renewal_date_added,
          call_back_scheduled,
          nationwide,
          erie,
          set_appointment,
          busy,
          farmers,
          duplicate,
          mobile_number
        },
      } = item;

      return createData(
        username,
        campaign_name,
        login_time,
        idle_time,
        utilization_time,
        call_count,
        unavailable,
        bad_number,
        decision_maker_not_yet_interested,
        skip,
        do_not_call_list,
        renewal_date_added,
        call_back_scheduled,
        nationwide,
        erie,
        busy,
        farmers,
        set_appointment,
        work_day,
        duplicate,
        mobile_number
      );
    });
  };

  const res = userReports.user_cam_log_array ?? [];

  const tableRows = convertApiResponseToRows(res);

  return (
    <Box>
      <CampaignInfoTable rows={tableRows} filterName={filterName} />
    </Box>
  );
};
