import {
  CALL_HISTORY_SEARCH_FAILURE,
  CALL_HISTORY_SEARCH_IN_PROGRESS,
  CALL_HISTORY_SEARCH_SUCCESS,
  CAMPAIGN_IDLE_TIME_MANAGER_FAILURE,
  CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS,
  CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS,
  CAMPAIGN_REPORT_SEARCH_FAILURE,
  CAMPAIGN_REPORT_SEARCH_IN_PROGRESS,
  CAMPAIGN_REPORT_SEARCH_SUCCESS,
  CAMPAIGN_STOP_FAILURE,
  CAMPAIGN_STOP_IN_PROGRESS,
  CAMPAIGN_STOP_SUCCESS,
  CREATE_CALL_HISTORY_FAILURE,
  CREATE_CALL_HISTORY_IN_PROGRESS,
  CREATE_CALL_HISTORY_SUCCESS,
  CWTR_SUCCESS,
  GET_CURRENT_ONLINE_USERS_FAILURE,
  GET_CURRENT_ONLINE_USERS_IN_PROGRESS,
  GET_CURRENT_ONLINE_USERS_SUCCESS,
  USER_REPORT_SEARCH_FAILURE,
  USER_REPORT_SEARCH_IN_PROGRESS,
  USER_REPORT_SEARCH_SUCCESS,
  IDLE_TIME_MANAGER_FAILURE,
  IDLE_TIME_MANAGER_IN_PROGRESS,
  IDLE_TIME_MANAGER_SUCCESS,
} from "../actions/report/types";
import initialState from "../initialState";

export const reports = (state = initialState.reports, action) => {
  switch (action.type) {
    case CREATE_CALL_HISTORY_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CREATE_CALL_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CREATE_CALL_HISTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CALL_HISTORY_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        callHistoryReports: [],
      };
    case CALL_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        callHistoryReports: action.data,
      };
    case CALL_HISTORY_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        callHistoryReports: [],
        errorMessage: action?.error?.message,
      };
    case USER_REPORT_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        userReports: {},
      };
    case USER_REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        userReports: action.data,
      };
    case USER_REPORT_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        userReports: {},
        errorMessage: action?.error?.message,
      };
    case CAMPAIGN_REPORT_SEARCH_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        campaignReport: {},
      };
    case CAMPAIGN_REPORT_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        campaignReport: action.data,
      };
    case CAMPAIGN_REPORT_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        campaignReport: {},
        errorMessage: action?.error?.message,
      };
    case CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case CAMPAIGN_IDLE_TIME_MANAGER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case CAMPAIGN_STOP_IN_PROGRESS:
      return {
        ...state,
        isStopCampaignLoading: true,
        error: false,
        errorMessage: "",
      };
    case CAMPAIGN_STOP_SUCCESS:
      return {
        ...state,
        isStopCampaignLoading: false,
        error: false,
        errorMessage: "",
      };
    case CAMPAIGN_STOP_FAILURE:
      return {
        ...state,
        isStopCampaignLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    case GET_CURRENT_ONLINE_USERS_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
        currentOnlineUsers: {},
      };
    case GET_CURRENT_ONLINE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        currentOnlineUsers: action.data,
      };
    case GET_CURRENT_ONLINE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        currentOnlineUsers: {},
        errorMessage: action?.error?.message,
      };
    case CWTR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
        CWTRReport: action.data,
      };
    case IDLE_TIME_MANAGER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: "",
      };
    case IDLE_TIME_MANAGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: "",
      };
    case IDLE_TIME_MANAGER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action?.error?.message,
      };
    default:
      return state;
  }
};
