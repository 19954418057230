import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NavLink, Link } from "react-router-dom";
import "../Header/Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import LockClockIcon from "@mui/icons-material/LockClock";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import { Dialer } from "./Dialer";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions";
import { Loader } from "../Common";
import { getUserProfile } from "../../store/actions/profile";

import logo3 from '../../../assets/images/logo3.png'
import profileNew from '../../../assets/images/profile_new.jpg'
import dialNew from '../../../assets/images/dial_new.png'


export const Header = ({ pathname }) => {
  const [isMenu, setIsMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleClass = () => {
    setIsMenu((prevState) => !prevState);
    setResponsiveclose((prevState) => !prevState);
  };

  const toggleSubmenu = (menuIndex) => {
    setActiveSubMenu((prevState) =>
      prevState === menuIndex ? null : menuIndex
    );
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  }

  const isLoading = useSelector((state) => state?.loginAuth?.loading);
  const isProfileLoading = useSelector((state) => state?.profile?.isLoading);
  const userInfo = useSelector((state) => state?.loginAuth?.user_details);

  const getProfileData = () => {
    const payload = {
      agent_id: userInfo?.id,
    };
    dispatch(
      getUserProfile(payload, () => {
        navigate("/Profile");
      })
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    handleClose();
    dispatch(logout());
  };

  const navigate = useNavigate();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setIsTooltipOpen((prevState) => !prevState);
  };

  const renderClientHeader = () => {
    if (
      userInfo?.user_type === "Administration" ||
      userInfo?.user_type === "Employee"
    ) {
      return (
        <>
          <li className="menu-item">
            <NavLink
              exact
              activeClassName="is-active"
              onClick={toggleClass}
              to={`/HomeComponent`}
            >
              Dashboard
            </NavLink>
          </li>
          <li
            className={`menu-item sub__menus__arrows ${activeSubMenu === 1 ? "active" : ""
              }`}
          >
            <Link to="#" onClick={() => toggleSubmenu(1)}>
              Campaign
              <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <ul
              className={`sub__menus ${activeSubMenu === 1 ? "sub__menus__Active" : ""
                }`}
            >
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/CampaignsList`}
                >
                  Campaign List
                </NavLink>
              </li>
              {userInfo?.user_type === "Administration" && (
                <>
                  <li>
                    <NavLink
                      vLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/NewCampaigns`}
                    >
                      New Campaign
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/SearchCampaigns`}
                    >
                      Search Campaign
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li
            className={`menu-item sub__menus__arrows ${activeSubMenu === 2 ? "active" : ""
              }`}
          >
            <Link to="#" onClick={() => toggleSubmenu(2)}>
              Leads
              <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <ul
              className={`sub__menus ${activeSubMenu === 2 ? "sub__menus__Active" : ""
                }`}
            >
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/LeadList`}
                >
                  Leads List
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/NewLead`}
                >
                  New Lead
                </NavLink>
              </li>
              {userInfo?.user_type === "Administration" && (
                <>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/UploadLead`}
                    >
                      Upload Lead
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </li>
          <li
            className={`menu-item sub__menus__arrows ${activeSubMenu === 3 ? "active" : ""
              }`}
          >
            <Link to="#" onClick={() => toggleSubmenu(3)}>
              Appointments
              <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <ul
              className={`sub__menus ${activeSubMenu === 3 ? "sub__menus__Active" : ""
                }`}
            >
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/AppointmentsList`}
                >
                  Appointments List
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/NewAppointment`}
                >
                  New Appointment
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/Calendar`}
                >
                  Calendar
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu-item">
            <NavLink
              exact
              activeClassName="is-active"
              onClick={toggleClass}
              to={`/Callback`}
            >
              Call Back
            </NavLink>
          </li>
          <li
            className={`menu-item sub__menus__arrows ${activeSubMenu === 4 ? "active" : ""
              }`}
          >
            <Link to="#" onClick={() => toggleSubmenu(4)}>
              Reports
              <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <ul
              className={`sub__menus ${activeSubMenu === 4 ? "sub__menus__Active" : ""
                }`}
            >
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/UserReport`}
                >
                  User Reports
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/CallHistoryReport`}
                >
                  Call History Reports
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/CampaignReport`}
                >
                  Campaign Reports
                </NavLink>
              </li>
              {userInfo?.user_type === "Administration" && (
                <>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/CWTRReport`}
                    >
                      CWTR
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/OnlineUserReport`}
                    >
                      Current Online Users
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </li>
          {userInfo?.user_type === "Administration" && (
            <>
              <li
                className={`menu-item sub__menus__arrows ${activeSubMenu === 5 ? "active" : ""
                  }`}
              >
                <Link to="#" onClick={() => toggleSubmenu(5)}>
                  Prospects
                  <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
                </Link>
                <ul
                  className={`sub__menus ${activeSubMenu === 5 ? "sub__menus__Active" : ""
                    }`}
                >
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/ProspectsList`}
                    >
                      Prospects List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/CreateProspects`}
                    >
                      New Prospects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/UploadProspects`}
                    >
                      Upload Prospects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/CallResult`}
                    >
                      Call Result
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li
                className={`menu-item sub__menus__arrows ${activeSubMenu === 6 ? "active" : ""
                  }`}
              >
                <Link to="#" onClick={() => toggleSubmenu(6)}>
                  Employee
                  <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
                </Link>
                <ul
                  className={`sub__menus ${activeSubMenu === 6 ? "sub__menus__Active" : ""
                    }`}
                >
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/EmployeesList`}
                    >
                      Employee List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/Create`}
                    >
                      Add Employee
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/UploadEmployee`}
                    >
                      Upload Employee
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={`menu-item sub__menus__arrows ${activeSubMenu === 9 ? "active" : ""
                  }`}
              >
                <Link to="#" onClick={() => toggleSubmenu(9)}>
                  Admin
                  <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
                </Link>
                <ul
                  className={`sub__menus ${activeSubMenu === 9 ? "sub__menus__Active" : ""
                    }`}
                >
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/AdminList`}
                    >
                      Admin List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/CreateAdmin`}
                    >
                      Add Admin
                    </NavLink>
                  </li>
                  <li>
                  </li>
                </ul>
              </li>

              <li
                className={`menu-item sub__menus__arrows ${activeSubMenu === 7 ? "active" : ""
                  }`}
              >
                <Link to="#" onClick={() => toggleSubmenu(7)}>
                  Client
                  <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
                </Link>
                <ul
                  className={`sub__menus ${activeSubMenu === 7 ? "sub__menus__Active" : ""
                    }`}
                >
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/ClientList`}
                    >
                      Client List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/AddClient`}
                    >
                      Add Client
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/UploadClient`}
                    >
                      Upload Client
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleClass}
                      activeClassName="is-active"
                      to={`/CommercialInvoices`}
                    >
                      Commercial Invoices
                    </NavLink>
                  </li>
                  {/* <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/InvoiceList`}
                      >
                        Invoice List
                      </NavLink>
                    </li> */}
                  {/* <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/InvoiceCreate`}
                        >
                          Invoice Create
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to={`/PaymentDetail`}
                        >
                          Add Payemnt Detail
                        </NavLink>
                      </li> */}
                </ul>
              </li>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <li className="menu-item">
            <NavLink
              exact
              activeClassName="is-active"
              onClick={toggleClass}
              to={`/HomeComponent`}
            >
              Dashboard
            </NavLink>
          </li>
          <li
            className={`menu-item sub__menus__arrows ${activeSubMenu === 1 ? "active" : ""
              }`}
          >
            <Link to="#" onClick={() => toggleSubmenu(8)}>
              Commercial
              <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <ul
              className={`sub__menus ${activeSubMenu === 8 ? "sub__menus__Active" : ""
                }`}
            >
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/LeadList`}
                >
                  Lead List
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/AppointmentsList`}
                >
                  Appointment List
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/Calendar`}
                >
                  Calendar
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`/ClientInvoices`}
                >
                  Invoices
                </NavLink>
              </li>
            </ul>
          </li>
        </>
      );
    }
  };

  return (
    <>
      <Box>
        <header className="header__middle">
          <div className="row">
            {/* Add Logo  */}

            <div className="header__middle__menus">
              <div className="header__middle__logo">
                <NavLink exact activeClassName="is-active" to="/">
                  <img loading="lazy" src={logo3} alt="logo" />
                </NavLink>
              </div>
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {isResponsiveclose ? (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none", position:'fixed', top: 10, left: 10 }}
                      onClick={toggleClass}
                    >
                      <CloseIcon />
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      <MenuIcon />
                    </span>
                  </>
                )}
                <ul className={boxClass.join(" ")}>
                  {renderClientHeader()}
                  {(userInfo?.user_type === "Administration" ||
                    userInfo?.user_type === "Employee") && (
                      <li>
                        <Box
                          sx={{
                            ml: 5,
                            mt: 1,
                            width: "38px",
                          }}
                        >
                          <button
                            onClick={handleTooltipToggle}
                            className="dial_icon"
                          >
                            <img loading="lazy" src={dialNew} alt="logo" />
                          </button>
                          {/* <Tooltip
                          open={isTooltipOpen} 
                          title={<Dialer  
                          onClose={()=>{
                            setIsTooltipOpen(false);
                          }}
                          />}
                          arrow
                          className="dial_tooltip"
                        >
                          
                        </Tooltip> */}
                        </Box>
                      </li>
                    )}
                </ul>
              </nav>
            </div>

            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  width: "45px",
                  minWidth: "45px",
                  height: "45px",
                  borderColor: "#fff",
                  border: 2,
                  color: "#fff",
                  size: "small",
                  boxShadow: "0",
                  borderRadius: "50%",
                  p: 0,
                  overflow: "hidden",
                }}
              >
                <img
                  src={profileNew}
                  alt="profile"
                  className="profile_img"
                  loading="lazy"
                />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{ border: 1, borderColor: "#32c3a3" }}
              >
                <MenuItem
                  onClick={() => {
                    getProfileData();
                    handleClose();
                  }}
                  sx={{
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: 600,
                    "&:hover": { backgroundColor: "#32c3a3", color: "#fff" },
                  }}
                >
                  <PersonIcon sx={{ mr: 1, fontSize: "20px" }} />
                  View Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/ChangePassword");
                    handleClose();
                  }}
                  sx={{
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: 600,
                    "&:hover": { backgroundColor: "#32c3a3", color: "#fff" },
                  }}
                >
                  <LockClockIcon sx={{ mr: 1, fontSize: "20px" }} />
                  Change Password
                </MenuItem>
                {userInfo?.user_type === "Administration" && (
                  <MenuItem
                    onClick={() => {
                      window.open(
                        "https://development.leadorchard.com/admin/",
                        "_blank"
                      );
                      handleClose();
                    }}
                    sx={{
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.87)",
                      fontWeight: 600,
                      "&:hover": { backgroundColor: "#32c3a3", color: "#fff" },
                    }}
                  >
                    <DashboardIcon sx={{ mr: 1, fontSize: "20px" }} />
                    Admin Portal
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleClose();
                  }}
                  sx={{
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: 600,
                    "&:hover": { backgroundColor: "#32c3a3", color: "#fff" },
                  }}
                >
                  <LogoutIcon sx={{ mr: 1, fontSize: "20px" }} />
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </header>
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "#6DD6C2",
            py: 1,
            width: "100%",
            textAlign: "center",
            zIndex: "99",
          }}
        >
          <Typography
            component="h2"
            variant="h2"
            sx={{
              fontSize: 14,
              color: "#000",
              fontWeight: 500,
              display: "initial",
            }}
          >
            Copyright Lead Orchard © 2023-2024
          </Typography>
        </Box>
        <Loader loading={isLoading || isProfileLoading} />

        {isTooltipOpen && (
          <Dialer
            onClose={() => {
              setIsTooltipOpen(false);
            }}
          />
        )}
      </Box>
    </>
  );
};
