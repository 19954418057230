export const CREATE_APPOINTMENT_IN_PROGRESS = "CREATE_APPOINTMENT_IN_PROGRESS";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILURE = "CREATE_APPOINTMENT_FAILURE";

//appointment search
export const GET_APPOINTMENT_SEARCH_IN_PROGRESS = 'GET_APPOINTMENT_SEARCH_IN_PROGRESS';
export const GET_APPOINTMENT_SEARCH_SUCCESS = 'GET_APPOINTMENT_SEARCH_SUCCESS';
export const GET_APPOINTMENT_SEARCH_FAILURE = 'GET_APPOINTMENT_SEARCH_FAILURE';


export const DELETE_APPOINTMENT_IN_PROGRESS = 'DELETE_APPOINTMENT_IN_PROGRESS';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE';


export const GET_All_EMPLOYEES_IN_PROGRESS = "GET_All_EMPLOYEES_IN_PROGRESS";
export const GET_All_EMPLOYEES_SUCCESS = "GET_All_EMPLOYEES_SUCCESS";
export const GET_All_EMPLOYEES_FAILURE = "GET_All_EMPLOYEES_FAILURE";


export const GET_All_NEW_EMPLOYEES_IN_PROGRESS = "GET_All_NEW_EMPLOYEES_IN_PROGRESS";
export const GET_All_NEW_EMPLOYEES_SUCCESS = "GET_All_NEW_EMPLOYEES_SUCCESS";
export const GET_All_NEW_EMPLOYEES_FAILURE = "GET_All_NEW_EMPLOYEES_FAILURE";


export const GET_CALENDAR_EVENTS_IN_PROGRESS = 'GET_CALENDAR_EVENTS_IN_PROGRESS';
export const GET_CALENDAR_EVENTS_SUCCESS = 'GET_CALENDAR_EVENTS_SUCCESS';
export const GET_CALENDAR_EVENTS_FAILURE = 'GET_CALENDAR_EVENTS_FAILURE';

export const CALENDAR_SEARCH_IN_PROGRESS = "CALENDAR_SEARCH_IN_PROGRESS";
export const CALENDAR_SEARCH_SUCCESS = "CALENDAR_SEARCH_SUCCESS";
export const CALENDAR_SEARCH_FAILURE = "CALENDAR_SEARCH_FAILURE";

export const CREATE_EVENT_IN_PROGRESS = 'CREATE_EVENT_IN_PROGRESS';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export const CLEAR_EVENT = 'CLEAR_EVENT';

// Action Types
export const DELETE_CALENDAR_EVENT_REQUEST = 'DELETE_CALENDAR_EVENT_REQUEST';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE';
