import React, { useEffect } from "react";
import { BrowserCacheLocation, LogLevel } from "@azure/msal-browser";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  LoginPage,
  HomePage,
  ClientPage,
  ProspectsPage,
  CampaignPage,
  LeadPage,
  CallBackPage,
  ReportPage,
  EmployeePage,
} from "./pages";
import { useSelector } from "react-redux";
import AuthRoute from "./AuthRoute";
import { Header, PaymentDetail, ClientViewComponent } from "./components";
import { AppointmentPage } from "./pages/AppointmentPage";
import { ProfilePage } from "./pages/ProfilePage";
import { ForbiddenComponent } from "./components/Common/ForbiddenComponent";
import {
  CallHistory,
  ClientInvoice,
  ClientInvoices,
} from "./components/ClientView";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, EventType, PublicClientApplication } from "@azure/msal-browser";
import BlankPage from "./pages/BlankPage";
import PageNotFound from "./pages/PageNotFound";
import { AdminPage } from "./pages/AdminPage";

const App = () => {
  const isLoggedIn = useSelector((state) => state?.loginAuth?.isLoggedIn);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     // Redirect to the home page if the user is logged in
  //     navigate("/HomeComponent");
  //   }
  // }, [isLoggedIn, navigate]);

  const msalConfig = {
    auth: {
      clientId: "64b2d921-a4db-4f99-b43e-b21a79b785e1",
      authority: "https://login.microsoftonline.com/common/",
      redirectUri: `${window.location.origin}/Profile`,
      postLogoutRedirectUri: "/",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "sessionStorage" // "sessionStorage"
    },
  }
  // CLIENT_ID = "64b2d921-a4db-4f99-b43e-b21a79b785e1"
  // CLIENT_SECRET = "Al48Q~W9I_-C4HvV7W4uOayoUNn5Y7~DtEZXucA1"

  // AUTHORITY = "https://login.microsoftonline.com/common/"
  // API_LOCATION = "http://localhost:8000"

  const pca = new PublicClientApplication(msalConfig);

  // if(pca.getActiveAccount() && pca.getAllAccounts().length>0){
  //   pca.setActiveAccount(pca.getActiveAccount()[0]);

  // }
  //   pca.addEventCallback((event)=>{
  //     if(event.eventType == "msal:loginSuccess" && event.payload.account){
  //       const account = event.payload.account;
  //       pca.setActiveAccount(account)
  //     }
  //   })

  const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/forbidden" element={<ForbiddenComponent />} />
        {isLoggedIn ? (
          <>
            <Route
              path="/login"
              element={
                <AuthRoute
                  element={<HomePage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/"
              element={
                <AuthRoute
                  element={<HomePage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/ClientInvoices"
              element={
                <AuthRoute
                  element={<ClientInvoices />}
                  allowedRoles={["Client"]}
                />
              }
            />
            <Route
              path="/ClientViewComponent"
              element={
                <AuthRoute
                  element={<ClientViewComponent />}
                  allowedRoles={["Client"]}
                />
              }
            />

            <Route
              path="/HomeComponent"
              element={
                <AuthRoute
                  element={<HomePage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/EmployeesList"
              element={
                <AuthRoute
                  element={<EmployeePage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/Create"
              element={
                <AuthRoute
                  element={<EmployeePage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/UploadEmployee"
              element={
                <AuthRoute
                  element={<EmployeePage />}
                  allowedRoles={["Administration"]}
                />
              }
            />

            <Route
              path="/AdminList"
              element={
                <AuthRoute
                  element={<AdminPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />

            <Route
              path="/CreateAdmin"
              element={
                <AuthRoute
                  element={<AdminPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />

            <Route
              path="/ClientList"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/AddClient"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/UploadClient"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/InvoiceList"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/InvoiceCreate"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/CommercialInvoices"
              element={
                <AuthRoute
                  element={<ClientPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/ProspectsList"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/CreateProspects"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/UploadProspects"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/X_UploadProspects"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/CallResult"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/ShowProspect"
              element={
                <AuthRoute
                  element={<ProspectsPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/PaymentDetail"
              element={
                <AuthRoute
                  element={<PaymentDetail />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/Callback"
              element={
                <AuthRoute
                  element={<CallBackPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/AppointmentsList"
              element={
                <AuthRoute
                  element={<AppointmentPage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/NewAppointment"
              element={
                <AuthRoute
                  element={<AppointmentPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/Calendar"
              element={
                <AuthRoute
                  element={<AppointmentPage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/LeadList"
              element={
                <AuthRoute
                  element={<LeadPage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/NewLead"
              element={
                <AuthRoute
                  element={<LeadPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/UploadLead"
              element={
                <AuthRoute
                  element={<LeadPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />

            <Route
              path="/CampaignsList"
              element={
                <AuthRoute
                  element={<CampaignPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/NewCampaigns"
              element={
                <AuthRoute
                  element={<CampaignPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/SearchCampaigns"
              element={
                <AuthRoute
                  element={<CampaignPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/Profile"
              element={
                <AuthRoute
                  element={<ProfilePage />}
                  allowedRoles={["Administration", "Client", "Employee"]}
                />
              }
            />
            <Route
              path="/ChangePassword"
              element={
                <AuthRoute
                  element={<ProfilePage />}
                  allowedRoles={["Administration", "Client", "Employee"]}
                />
              }
            />

            <Route
              path="/UserReport"
              element={
                <AuthRoute
                  element={<ReportPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/CallHistoryReport"
              element={
                <AuthRoute
                  element={<ReportPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/CampaignReport"
              element={
                <AuthRoute
                  element={<ReportPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/CWTRReport"
              element={
                <AuthRoute
                  element={<ReportPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/OnlineUserReport"
              element={
                <AuthRoute
                  element={<ReportPage />}
                  allowedRoles={["Administration"]}
                />
              }
            />
            <Route
              path="/CampaignsShow"
              element={
                <AuthRoute
                  element={<CampaignPage />}
                  allowedRoles={["Administration", "Employee"]}
                />
              }
            />
            <Route
              path="/ShowLead"
              element={
                <AuthRoute
                  element={<LeadPage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/Redirect"
              element={
                <AuthRoute
                  element={<BlankPage />}
                  allowedRoles={["Administration", "Employee", "Client"]}
                />
              }
            />
            <Route
              path="/*"
              element={<PageNotFound />}
            />
          </>
        ) : (
          <Route path="/*" element={<LoginPage />} />
        )}
      </Routes>
    );
  };

  return (
    <MsalProvider instance={pca}>
      <BrowserRouter>
        {isLoggedIn && <Header />}
        <AppRoutes />
      </BrowserRouter>
    </MsalProvider>
  );
};

export default App;
