import {
  BASE_URL,
  CALL_HISTORY_SEARCH_URL,
  CAMPAIGN_IDLE_TIME_MANAGER_URL,
  CAMPAIGN_REPORT_SEARCH_URL,
  CAMPAIGN_STOP_URL,
  CREATE_CALL_HISTORY_URL,
  UPDATE_CALL_HISTORY_URL,
  CWTR_URL,
  GET_CURRENT_ONLINE_USERS_URL,
  USER_REPORT_SEARCH_URL,
  // IDLE_TIME_MANAGER_URL,
} from "../../../../app/config/api_urls";
import {
  ERROR_MESSAGE,
  ERROR_TYPE,
  KEYS,
  getItemFromLocalStorage,
} from "../../../helpers";
import { getRequest, postRequest } from "../../../services";
import { logout } from "../authentication";
import { LOGOUT } from "../authentication/types";
import {
  CALL_HISTORY_SEARCH_FAILURE,
  CALL_HISTORY_SEARCH_IN_PROGRESS,
  CALL_HISTORY_SEARCH_SUCCESS,
  CAMPAIGN_IDLE_TIME_MANAGER_FAILURE,
  CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS,
  CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS,
  CAMPAIGN_REPORT_SEARCH_FAILURE,
  CAMPAIGN_REPORT_SEARCH_IN_PROGRESS,
  CAMPAIGN_REPORT_SEARCH_SUCCESS,
  CAMPAIGN_STOP_FAILURE,
  CAMPAIGN_STOP_IN_PROGRESS,
  CAMPAIGN_STOP_SUCCESS,
  CREATE_CALL_HISTORY_FAILURE,
  CREATE_CALL_HISTORY_IN_PROGRESS,
  CREATE_CALL_HISTORY_SUCCESS,
  UPDATE_CALL_HISTORY_FAILURE,
  UPDATE_CALL_HISTORY_IN_PROGRESS,
  UPDATE_CALL_HISTORY_SUCCESS,
  CWTR_SUCCESS,
  GET_CURRENT_ONLINE_USERS_FAILURE,
  GET_CURRENT_ONLINE_USERS_IN_PROGRESS,
  GET_CURRENT_ONLINE_USERS_SUCCESS,
  USER_REPORT_SEARCH_FAILURE,
  USER_REPORT_SEARCH_IN_PROGRESS,
  USER_REPORT_SEARCH_SUCCESS,
  IDLE_TIME_MANAGER_FAILURE,
  IDLE_TIME_MANAGER_IN_PROGRESS,
  IDLE_TIME_MANAGER_SUCCESS,
} from "./types";
import toast from "react-hot-toast";

// Action Creators for creating call history
export const createCallHistoryInProgress = () => ({
  type: CREATE_CALL_HISTORY_IN_PROGRESS,
});

export const createCallHistorySuccess = (data) => ({
  type: CREATE_CALL_HISTORY_SUCCESS,
  data,
});

export const createCallHistoryFailure = (error) => ({
  type: CREATE_CALL_HISTORY_FAILURE,
  error,
});

export const createCallHistory = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(createCallHistoryInProgress());

      // Make API request to create the call history
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${CREATE_CALL_HISTORY_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(createCallHistorySuccess(response?.data));
        cb();
      } else {
        dispatch(
          createCallHistoryFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        createCallHistoryFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
    }
  };
};

// Action Creators for creating call history
export const updateCallHistoryInProgress = () => ({
  type: UPDATE_CALL_HISTORY_IN_PROGRESS,
});

export const updateCallHistorySuccess = (data) => ({
  type: UPDATE_CALL_HISTORY_SUCCESS,
  data,
});

export const updateCallHistoryFailure = (error) => ({
  type: UPDATE_CALL_HISTORY_FAILURE,
  error,
});

export const updateCallHistory = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(updateCallHistoryInProgress());

      // Make API request to create the call history
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${UPDATE_CALL_HISTORY_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(updateCallHistorySuccess(response?.data));
        cb();
      } else {
        dispatch(
          updateCallHistoryFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        updateCallHistoryFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
    }
  };
};

// Action Creators
// Action Creators for searching call history
export const callHistorySearchInProgress = () => ({
  type: CALL_HISTORY_SEARCH_IN_PROGRESS,
});

export const callHistorySearchSuccess = (data) => ({
  type: CALL_HISTORY_SEARCH_SUCCESS,
  data,
});

export const callHistorySearchFailure = (error) => ({
  type: CALL_HISTORY_SEARCH_FAILURE,
  error,
});

export const callHistorySearch = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(callHistorySearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for call history

      const URL = `${BASE_URL}${CALL_HISTORY_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(callHistorySearchSuccess(response?.data));
        cb();
      } else {
        dispatch(
          callHistorySearchFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        callHistorySearchFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const userReportSearchInProgress = () => ({
  type: USER_REPORT_SEARCH_IN_PROGRESS,
});

export const userReportSearchSuccess = (data) => ({
  type: USER_REPORT_SEARCH_SUCCESS,
  data,
});

export const userReportSearchFailure = (error) => ({
  type: USER_REPORT_SEARCH_FAILURE,
  error,
});

export const userReportSearch = (payload, cb = () => { }) => {

  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(userReportSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for user reports

      const URL = `${BASE_URL}${USER_REPORT_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        dispatch(userReportSearchSuccess(response?.data));
        cb();
      } else {
        dispatch(
          userReportSearchFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        userReportSearchFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};

export const campaignReportSearchInProgress = () => ({
  type: CAMPAIGN_REPORT_SEARCH_IN_PROGRESS,
});

export const campaignReportSearchSuccess = (data) => ({
  type: CAMPAIGN_REPORT_SEARCH_SUCCESS,
  data,
});

export const campaignReportSearchFailure = (error) => ({
  type: CAMPAIGN_REPORT_SEARCH_FAILURE,
  error,
});

export const CWTRSuccess = (data) => ({
  type: CWTR_SUCCESS,
  data,
});

export const campaignReportSearch = (
  payload,
  isFromCwtr = false,
  cb = () => { }
) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(campaignReportSearchInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to search for campaign reports

      const URL = `${BASE_URL}${isFromCwtr ? CWTR_URL : CAMPAIGN_REPORT_SEARCH_URL}`;

      const response = await getRequest(URL, token, payload);

      // Check response status
      if (response?.status === 200) {
        isFromCwtr
          ? dispatch(CWTRSuccess(response?.data))
          : dispatch(campaignReportSearchSuccess(response?.data));
        cb();
      } else {
        dispatch(
          campaignReportSearchFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      // const errorMessage =error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      let errArr = []
      for (let key in error?.response?.data) {
        let value = error?.response?.data[key];
        if (typeof value === 'object') {
          let nestedValue = '';
          for (let nestedKey in value) {
            nestedValue += `${value[nestedKey]}`;
          }
          value = nestedValue.trim();
        }
        let errToast = `${value}`
        errArr.push(errToast)
        toast.error(errToast, {
          position: "right-top",
        });
      }
      dispatch(
        campaignReportSearchFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errArr.join(", "),
        })
      );
    }
  };
};

export const campaignIdleTimeManagerInProgress = () => ({
  type: CAMPAIGN_IDLE_TIME_MANAGER_IN_PROGRESS,
});

export const campaignIdleTimeManagerSuccess = (data) => ({
  type: CAMPAIGN_IDLE_TIME_MANAGER_SUCCESS,
  data,
});

export const campaignIdleTimeManagerFailure = (error) => ({
  type: CAMPAIGN_IDLE_TIME_MANAGER_FAILURE,
  error,
});

export const campaignIdleTimeManager = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(campaignIdleTimeManagerInProgress());

      // Make API request for campaignIdleTimeManager
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${CAMPAIGN_IDLE_TIME_MANAGER_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(campaignIdleTimeManagerSuccess(response?.data));
        cb();
      } else {
        dispatch(
          campaignIdleTimeManagerFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        campaignIdleTimeManagerFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
    }
  };
};

// IdleTimeManager
export const idleTimeManagerInProgress = () => ({
  type: IDLE_TIME_MANAGER_IN_PROGRESS,
});

export const idleTimeManagerSuccess = (data) => ({
  type: IDLE_TIME_MANAGER_SUCCESS,
  data,
});

export const idleTimeManagerFailure = (error) => ({
  type: IDLE_TIME_MANAGER_FAILURE,
  error,
});


export const idleTimeManager = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(idleTimeManagerInProgress());

      // Make API request for idleTimeManager
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      // let URL = `${BASE_URL}${IDLE_TIME_MANAGER_URL}`;

      const response = await postRequest(URL, payload, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(idleTimeManagerSuccess(response?.data));
        cb();
      } else {
        dispatch(
          idleTimeManagerFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        idleTimeManagerFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
    }
  };
};



export const campaignStopInProgress = () => ({
  type: CAMPAIGN_STOP_IN_PROGRESS,
});

export const campaignStopSuccess = (data) => ({
  type: CAMPAIGN_STOP_SUCCESS,
  data,
});

export const campaignStopFailure = (error) => ({
  type: CAMPAIGN_STOP_FAILURE,
  error,
});

export const campaignStop = (campaignLogId, cb = () => { }) => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(campaignStopInProgress());

      // Make API request for campaignStop
      const token = getItemFromLocalStorage(KEYS.TOKEN);

      let URL = `${BASE_URL}${CAMPAIGN_STOP_URL}${campaignLogId}/`;

      const response = await postRequest(URL, {}, "", token);

      // Check response status
      if (response?.status === 201 || response?.status === 200) {
        dispatch(campaignStopSuccess(response?.data));
        cb();
        toast.success("Campaign Stopped", {
          position: "bottom-center",
        });
      } else {
        dispatch(
          campaignStopFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        campaignStopFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
    }
  };
};

// Action Creators for getCurrentOnlineUsers
export const GetCurrentOnlineUsersInProgress = () => ({
  type: GET_CURRENT_ONLINE_USERS_IN_PROGRESS,
});

export const GetCurrentOnlineUsersSuccess = (data) => ({
  type: GET_CURRENT_ONLINE_USERS_SUCCESS,
  data,
});

export const GetCurrentOnlineUsersFailure = (error) => ({
  type: GET_CURRENT_ONLINE_USERS_FAILURE,
  error,
});

export const GetCurrentOnlineUsers = () => {
  return async (dispatch) => {
    try {
      // Dispatch in progress action
      dispatch(GetCurrentOnlineUsersInProgress());

      const token = getItemFromLocalStorage(KEYS.TOKEN);
      // Make API request to get current online users
      const response = await getRequest(
        `${BASE_URL}${GET_CURRENT_ONLINE_USERS_URL}`,
        token
      );

      // Check response status
      if (response?.status === 200) {
        dispatch(GetCurrentOnlineUsersSuccess(response?.data));
      } else {
        dispatch(
          GetCurrentOnlineUsersFailure({
            type: ERROR_TYPE.API.REPORTS.UNKNOWN,
            message: response?.statusText ?? ERROR_MESSAGE.API.UNKNOWN,
          })
        );
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // Unauthorized response received (status code 401)
        let tokenErr = error?.response?.data?.error.message
        toast.error(tokenErr, {
          position: "bottom-center",
        });
        dispatch(logout());
        return;
      }
      const errorMessage =
        error?.response?.data?.error?.message ?? ERROR_MESSAGE.API.UNKNOWN;
      dispatch(
        GetCurrentOnlineUsersFailure({
          type: ERROR_TYPE.API.REPORTS.UNKNOWN,
          message: errorMessage,
        })
      );
      toast.error(errorMessage, {
        position: "bottom-center",
      });
    }
  };
};
